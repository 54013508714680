import React, { useState, useEffect } from "react";
import style from "./User.module.css";
import { BsPersonCircle } from "react-icons/bs";
import { RiShareForwardFill } from "react-icons/ri";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiEdit } from "react-icons/bi";
import { FcSearch } from "react-icons/fc";
import { AiOutlineClose } from "react-icons/ai";
import { BiLockOpenAlt } from "react-icons/bi";
import { baseUrl } from "../Url";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RoleGet } from "../../action/User";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import { PiPasswordBold } from "react-icons/pi";
import { AiOutlineInfoCircle } from "react-icons/ai";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { MdOutlinePersonSearch } from "react-icons/md";
import Loader from "../Loader/Loader";
import Pagination from "../Pagination/Pagination";
import Checkbox from "@mui/material/Checkbox";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import PerformanceIcon from "../../assets/Performance.png"

const User = () => {
  const key = JSON.parse(localStorage.getItem("access-token"));
  const adminId = localStorage.getItem("adminUserId");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { roles } = useSelector((state) => state.roleData);
  console.log(roles);
  const adminStatus = localStorage.getItem("adminStatus");

  const [userData, setUserData] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState("");
  const [selectedCLubId, setSelectedClubId] = useState("");
  const [clubList, setClubList] = useState([]);
  const [clubReadOnly, setClubReadOnly] = useState(true);
  const [userShow, setUserShow] = useState(false);
  const [roleChange, setRoleChange] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [selectedRoleName, setSelectedRoleName] = useState("");
  const [mentorChange, setMentorChange] = useState(false);
  const [mentorList, setMentorList] = useState([]);
  const [selectedMentorId, setSelectedMentorId] = useState("");
  const [mappedMentorList, setMappedMentorList] = useState(false);
  const [singleMentorList, setSingleMentorList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [memberCreate, setMemberCreate] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [countryName, setCountryName] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [editFirstName, setEditFirstName] = useState("");
  const [editSecondName, setEditSecondName] = useState("");
  const [editUserName, setEditUserName] = useState("");
  const [editPassword, setEditPassword] = useState("");
  const [editPhone, setEditPhone] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [editCountryName, setEditCountryName] = useState("");
  const [editRoleName, setEditRoleName] = useState("");
  const [addMemberShow, setAddMemberShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const [clubName, setClubName] = useState("");
  const [clubSelected, setClubSelected] = useState(false);
  const [clubSelectShow, setClubSelectShow] = useState(false);
  const [selectedClubs, setSelectedClubs] = useState([]);
  const [EditClubSelectShow, setEditClubSelectShow] = useState(false);
  const [userId, setUserId] = useState("");
  const [updatePopup, setUpdatePopup] = useState(false);
  const [editClubs, setEditClubs] = useState([]);
  const [memberDelete, setMemberDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const [passwordMessage, setpasswordMessage] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordId, setPasswordId] = useState("");
  const [showEditPassword, setShowEditPassword] = useState(false);
  const [conditionPopup, SetConditionPopup] = useState(false);
  const [clear, setClear] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [deleteName, setDeleteName] = useState("");
  const [loading, setLoading] = useState(false);
  const [memberCreationFail, setMemberCreationFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [apiResponse, setApiResponse] = useState(false);
  const [passwordCondition, setPasswordCondition] = useState(false);
  const [userCreation, setUserCreation] = useState(false);
  const [userDeletion, setUserDeletion] = useState(false);
  const [userUpdation, setUserUpdation] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [copyCredential, setCopyCredential] = useState(false);
  const [shareCredential, setShareCredential] = useState(false);
  const [credentil, setCredential] = useState({ username: "", password: "" });
  const [shareEmail, setShareEmail] = useState("");

  const handleRoleChange = (organizationId) => {
    const data = {
      orgId: organizationId,
      clubId: "",
    };
    console.log(data);
    dispatch(RoleGet(data));
  };
  const handleClubRoleChange = (id) => {
    const data = {
      orgId: selectedOrgId,
      clubId: id,
    };
    console.log(data);
    dispatch(RoleGet(data));
  };

  const countryData = [
    "Australia",
    "Canada",
    "India",
    "United Kingdom",
    "United States",
  ];
  const itemsPerPage = 14; // Adjust as needed
  const users = userData;
  const totalUsers = users.length;

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayUsers = users.slice(startIndex, endIndex);

  const handlePasswordChange = (e) => {
    setEditPassword(e.target.value);
    setpasswordMessage("");
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setpasswordMessage("");
  };

  const handleUpdateClick = () => {
    if (editPassword === confirmPassword) {
      passReset(confirmPassword);
      console.log("Passwords match. Make API request here.");
    } else {
      setpasswordMessage("Passwords do not match");
      setPasswordCondition(true);
    }
  };

  const handleCancelClick = () => {
    // Reset the form or close the popup
    setEditPassword("");
    setConfirmPassword("");
    setpasswordMessage("");
    setShowPasswordPopup(false);
  };

  const togglePassword = () => {
    setShowEditPassword(!showEditPassword);
  };

  const validateForm = () => {
    const errors = {};

    if (firstName.trim() === "") {
      errors.firstName = "First Name is required";
    }

    if (secondName.trim() === "") {
      errors.secondName = "Second Name is required";
    }

    if (userName.trim() === "") {
      errors.userName = "User Name is required";
    }

    if (password.trim() === "") {
      errors.password = "Password is required";
    } else {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,15})(?!\s).*$/;
      if (!passwordRegex.test(password.trim())) {
        errors.password = "Password does not match criteria";
      }
    }

    if (phone.trim() === "") {
      errors.phone = "Phone is required";
    } else {
      const numericPhoneRegex = /^[0-9]+$/;
      if (!numericPhoneRegex.test(phone.trim())) {
        errors.phone = "Phone must contain only numbers";
      }
    }
    if (email.trim() === "") {
      errors.email = "Email is required";
    } else {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      if (!emailRegex.test(email.trim())) {
        errors.email = "Invalid email format";
      }
    }

    // You can add more specific validation rules for email and role if needed

    if (selectedRoleId === "") {
      errors.selectedRole = "Role is required";
    }
    if (selectedClubs.length === 0) {
      errors.clubSelection = "Select at least one club";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const editForm = () => {
    const errors = {};

    if (editFirstName.trim() === "") {
      errors.editFirstName = "First Name is required";
    }

    if (editSecondName.trim() === "") {
      errors.editSecondName = "Second Name is required";
    }

    if (editUserName.trim() === "") {
      errors.editUserName = "User Name is required";
    }

    if (editPhone.trim() === "") {
      errors.editPhone = "Phone is required";
    }

    // You can add more specific validation rules for email and role if needed

    if (selectedRoleId === "") {
      errors.selectedRole = "Role is required";
    }
    if (editClubs.length === 0) {
      errors.editClubs = "At least one club must be selected";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCheckboxChange = (clubID) => {
    const newSelectedClubs = selectedClubs.includes(clubID)
      ? selectedClubs.filter((id) => id !== clubID)
      : [...selectedClubs, clubID];

    setSelectedClubs(newSelectedClubs);
  };

  const handleClub = (clubID) => {
    if (Array.isArray(editClubs)) {
      if (editClubs.includes(clubID)) {
        // Use filter to remove the clubID from the array
        setEditClubs(editClubs.filter((id) => id !== clubID));
      } else {
        // Use spread operator to add the clubID to the array
        setEditClubs([...editClubs, clubID]);
      }
    } else {
      // Initialize selectedClubs as an array with the current club ID
      setEditClubs([editClubs, clubID]);
    }
  };

  //Organization list API
  async function OrgGet() {
    const userId = localStorage.getItem("adminUserId");
    await fetch(`${baseUrl}/org/get/admin/per_users`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "orgData");
        setOrgList(data.data);
      });
  }

  //CLub list API
  async function ClubGet(OrgId) {
    await fetch(`${baseUrl}/club/list`, {
      method: "POST",
      body: JSON.stringify({
        OrganizationID: OrgId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setClubList(data.data);
      });
  }

  //user list
  async function usersListByOrg(orgId) {
    await fetch(`${baseUrl}/user/get/by/org/club`, {
      method: "POST",
      body: JSON.stringify({
        org_id: orgId,
        club_id: "",
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setUserData(data.data);
        setLoading(false);
      });
  }

  //user list
  async function usersList(clubId) {
    await fetch(`${baseUrl}/user/get/by/org/club`, {
      method: "POST",
      body: JSON.stringify({
        org_id: selectedOrgId,
        club_id: clubId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setUserData(data.data);
      });
  }

  //role change
  async function changeRole(roleid, id) {
    await fetch(`${baseUrl}/user/role/change/admin`, {
      method: "POST",
      body: JSON.stringify({
        user_id: id,
        role: roleid,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          usersList(selectedCLubId);
        }
      });
  }

  //mentor list
  async function allMentorList() {
    await fetch(`${baseUrl}/mentor/get/by/org`, {
      method: "POST",
      body: JSON.stringify({
        org_id: selectedOrgId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setMentorList(data.data);
        console.log(mentorList);
      });
  }

  //mentor update
  async function mentorUpdate(mentorID, UserID) {
    await fetch(`${baseUrl}/support/member/learner/mapping/add`, {
      method: "POST",
      body: JSON.stringify({
        SupportMemberID: mentorID,
        LearnerID: UserID,
        LearnerOrganizationID: selectedOrgId,
        LearnerClubID: selectedCLubId,
        MappingCreatedBy: adminId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          usersList(selectedCLubId);
        }
      });
  }

  //mapped mentor list
  async function mappedMentorView(UserID) {
    await fetch(`${baseUrl}/user/mapped/mentors`, {
      method: "POST",
      body: JSON.stringify({
        user_id: UserID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSingleMentorList(data.data);
      });
  }
  const validateFields = () => {
    if (!userName) {
      setErrorMessage("Username is required");
      return false;
    }
    if (!password) {
      setErrorMessage("Password is required");
      return false;
    } else {
      const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,15})(?!\s).*$/;
      if (!passwordRegex.test(password.trim())) {
        setErrorMessage("Password does not match criteria");
        return false;
      }
    }
    // if (!phone) {
    //   setErrorMessage("Phone number is required");
    //   return false;
    // } else {
    //   const numericPhoneRegex = /^[0-9]+$/;
    //   if (!numericPhoneRegex.test(phone.trim())) {
    //     setErrorMessage("Phone must contain only numbers");
    //     return false;
    //   }
    //   if (phone.trim().length !== 10) {
    //     setErrorMessage("Phone number must contain 10 digits");
    //     return false;
    //   }
    // }
    if (!email) {
      setErrorMessage("Email is required");
      return false;
    } else {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      if (!emailRegex.test(email.trim())) {
        setErrorMessage("Invalid email format");
        return false;
      }
    }
    if (selectedRoleId === "") {
      setErrorMessage("Role is required");
      return false;
    }
    if (clubSelected && selectedClubs.length === 0) {
      setErrorMessage("Select at least one club");
      return false;
    }

    return true;
  };

  //member add
  async function memberAdd(e) {
    const bodyData = {
      FirstName: firstName,
      MiddleName: "",
      LastName: secondName,
      Nickname: "",
      Username: userName,
      Password: password,
      DOB: "",
      Gender: "",
      ContactNumber: phone,
      Email: email,
      CommunicationAddress: "",
      PermanentAddress: "",
      BillingAddress: "",
      MemberRoles: selectedRoleId,
      CurrentAddress: "",
      SignupSource: "admin",
      org_id: selectedOrgId,
      role: selectedRoleName,
      club_id: selectedClubs,
      country: countryName,
    };
    // const isFormValid = validateForm();

    // if (isFormValid) {
    if (!validateFields()) {
      setMemberCreationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/account/signup/by/admin`, {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === "success" && clubSelected === false) {
            usersListByOrg(selectedOrgId);
            setMemberCreate(false);
            setUserCreation(true);
          } else if (data.status === "success" && clubSelected === true) {
            usersList(selectedCLubId);
            setMemberCreate(false);
            setUserCreation(true);
          } else {
            setApiError(data.message);
            setApiResponse(true);
          }
        });
    }
  }
  const validateEditFields = () => {
    if (!editUserName) {
      setErrorMessage("Username is required");
      return false;
    }
    // if (!editPhone) {
    //   setErrorMessage("Phone number is required");
    //   return false;
    // } else {
    //   const numericPhoneRegex = /^[0-9]+$/;
    //   if (!numericPhoneRegex.test(editPhone.trim())) {
    //     setErrorMessage("Phone must contain only numbers");
    //     return false;
    //   }
    //   if (editPhone.trim().length !== 10) {
    //     setErrorMessage("Phone number must contain 10 digits");
    //     return false;
    //   }
    // }
    if (!editEmail) {
      setErrorMessage("Email is required");
      return false;
    } else {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
      if (!emailRegex.test(editEmail.trim())) {
        setErrorMessage("Invalid email format");
        return false;
      }
    }
    if (selectedRoleId === "") {
      setErrorMessage("Role is required");
      return false;
    }

    return true;
  };

  //member edit

  async function memberEdit(e) {
    const bodyData = {
      user_id: userId,
      FirstName: editFirstName,
      MiddleName: "",
      LastName: editSecondName,
      Nickname: "",
      Username: editUserName,
      DOB: "",
      Gender: "",
      country:editCountryName,
      ContactNumber: editPhone,
      Email: editEmail,
      CommunicationAddress: "",
      PermanentAddress: "",
      BillingAddress: "",
      MemberRoles: selectedRoleId,
      CurrentAddress: "",
      SignupSource: "admin",
      org_id: selectedOrgId,
      role: selectedRoleName,
      club_id: editClubs,
    };
    if (!validateEditFields()) {
      setMemberCreationFail(true);
      return;
    } else {
      await fetch(`${baseUrl}/account/update/by/admin`, {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === "success" && clubSelected === false) {
            usersListByOrg(selectedOrgId);
            setUpdatePopup(false);
            setUserUpdation(true);
          } else if (data.status === "success" && clubSelected === true) {
            usersList(selectedCLubId);
            setUpdatePopup(false);
            setUserUpdation(true);
          } else {
            setApiError(data.message);
            setApiResponse(true);
          }
        });
    }
  }
  //member deleting api

  async function DeleteMember() {
    await fetch(`${baseUrl}/user/delete/by/admin`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true && clubSelected === false) {
          usersListByOrg(selectedOrgId);
          setMemberDelete(false);
          setUserDeletion(true);
        } else if (data.status === true && clubSelected === true) {
          usersList(selectedCLubId);
          setMemberDelete(false);
          setUserDeletion(true);
        }
      });
  }

  async function passReset(pass) {
    await fetch(`${baseUrl}/password/change`, {
      method: "POST",
      body: JSON.stringify({
        user_id: passwordId,
        new_pwd: pass,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setShowPasswordPopup(false);
          setPasswordChange(true);
        }
      });
  }
  async function credentialShare() {
    await fetch(`${baseUrl}/send/email`, {
      method: "POST",
      body: JSON.stringify({
        email: shareEmail,
        user_name: credentil.username,
        password: credentil.password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setShowSharePopup(false);
          setCredential({ username: "", password: "" });
          setShareCredential(true);
        } else {
          setMemberCreationFail(true);
          setErrorMessage("Please try again later");
          setShowSharePopup(false);
          setCredential({ username: "", password: "" });
        }
      });
  }

  const { profile } = useSelector((state) => state.profileData);

  useEffect(() => {
    if (adminStatus === false || adminStatus === "false") {
      const orgid = profile?.org_id;
      if (orgid !== undefined && orgid !== "") {
        ClubGet(orgid);
        setClubReadOnly(false);
        setSelectedOrgId(orgid);
        setUserShow(true);
        usersListByOrg(orgid);
        handleRoleChange(orgid);
        setAddMemberShow(true);
      }
    } else {
      OrgGet();
    }
  }, [adminStatus, profile]);

  useEffect(() => {
    OrgGet();
  }, [key]);

  const handleInputChange = (e) => {
    setLoading(true);
    const newValue = e.target.value;
    setSearchValue(newValue);

    // Update the 'clear' state based on the input value
    setClear(newValue.length > 0);

    // Call the search function with the updated value
    userSearch(newValue);
  };
  //user list search

  async function userSearch(value) {
    await fetch(`${baseUrl}/search/user`, {
      method: "POST",
      body: JSON.stringify({
        org_id: selectedOrgId,
        search: value,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setUserData(data.data);
        setLoading(false);
      });
  }
  const handleCopyCredentials = () => {
    const { username, password } = credentil;
    const credentialsText = `Username: ${username}, Password: ${password}`;

    navigator.clipboard
      .writeText(credentialsText)
      .then(() => {
        setCopyCredential(true);
        setShowSharePopup(false);
        setCredential({ username: "", password: "" });
      })
      .catch((error) => {
        setMemberCreationFail(true);
        setErrorMessage("Please try again later");
        setShowSharePopup(false);
        setCredential({ username: "", password: "" });
      });
  };

  return (
    <div className={style.Container}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={
          userCreation ||
          userDeletion ||
          userUpdation ||
          passwordChange ||
          copyCredential ||
          shareCredential
        }
        autoHideDuration={3000}
        onClose={() => {
          setUserCreation(false);
          setUserUpdation(false);
          setUserDeletion(false);
          setPasswordChange(false);
          setCopyCredential(false);
          setShareCredential(false);
        }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {userCreation
            ? "User Created successfully"
            : userDeletion
            ? "User Deleted successfully"
            : userUpdation
            ? "User Updated successfully"
            : passwordChange
            ? "Password Changed Successfully"
            : copyCredential
            ? "Credentials copied to clipboard!"
            : shareCredential
            ? "Credentials shared successfully"
            : ""}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={memberCreationFail}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setMemberCreationFail(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={apiResponse}
        sx={{ width: "15rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setApiResponse(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {apiError}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={passwordCondition}
        sx={{ width: "15rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setPasswordCondition(false);
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{ width: "100%", fontSize: "1.15rem" }}
        >
          {passwordMessage}
        </Alert>
      </Snackbar>
      <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>User Management</p>
        </div>
        <div className={style.HeadButton}>
          {addMemberShow ? (
            <button
              onClick={() => {
                setMemberCreate(true);
              }}
            >
              Add Member
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={style.secondHead}>
        <div className={style.select_section}>
          <div className={style.selectLeft}>
            {adminStatus === true || adminStatus === "true" ? (
              <FormControl sx={{ width: "50%", margin: "0 0.25rem" }}>
                <InputLabel id="demo-simple-select-label">
                  Select Organizations
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Organisations"
                  value={selectedOrgId}
                  onChange={(e) => {
                    const OrgID = e.target.value;
                    ClubGet(OrgID);
                    setClubReadOnly(false);
                    setSelectedOrgId(OrgID);
                    setUserShow(true);
                    usersListByOrg(OrgID);
                    setAddMemberShow(true);
                    handleRoleChange(OrgID);
                    setLoading(true);
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300, // Adjust the maximum height as needed
                      },
                    },
                  }}
                >
                  {orgList && orgList.length > 0 ? (
                    orgList.map((org) => (
                      <MenuItem key={org.org_id} value={org.org_id}>
                        {org.org_name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No organizations available</MenuItem>
                  )}
                </Select>
              </FormControl>
            ) : (
              ""
            )}
            <FormControl
              sx={{ width: "50%", margin: "0 0.25rem", zIndex: "0" }}
            >
              <InputLabel id="demo-simple-select-label">
                Select Club/Batch
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Clubs/Batchs"
                disabled={clubReadOnly}
                value={selectedCLubId}
                onChange={(e) => {
                  const ClubID = e.target.value;
                  if (ClubID !== "") {
                    usersList(ClubID);
                    setSelectedClubId(ClubID);
                    handleClubRoleChange(ClubID);
                    setClubSelected(true);
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300, // Adjust the maximum height as needed
                    },
                  },
                }}
              >
                {clubList && clubList.length > 0 ? (
                  clubList.map((club) => (
                    <MenuItem value={club.ClubID} key={club.ClubID}>
                      {club.ClubName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Clubs/Batchs available</MenuItem>
                )}
              </Select>
            </FormControl>
          </div>

          <div className={style.Search}>
            <Tooltip
              title={clubReadOnly ? "Please Select Orgnisation" : ""}
              arrow
            >
              <input
                disabled={clubReadOnly}
                type="search"
                value={searchValue}
                onChange={handleInputChange}
                name=""
                id=""
                placeholder="Search here"
              />
            </Tooltip>

            {searchValue ? "" : <MdOutlinePersonSearch />}
          </div>
        </div>
      </div>

      {userShow ? (
        <div className={style.userManagement}>
          {loading ? (
            <Loader />
          ) : (
            <div className={style.List}>
              {displayUsers.length > 0 &&
                displayUsers.map((data, i) => {
                  return (
                    <div className={style.userCards} key={i}>
                      <div className={style.cardTop}>
                        <div className={style.userName}>
                          <p>UserName</p>
                          <span>:</span>
                          <p>{data.Username}</p>
                        </div>
                        <div className={style.role}>
                          <p>Role</p>
                          <span>:</span>
                          <p>{data.role}</p>
                        </div>
                      </div>
                      <div className={style.cardBotton}>
                        <Tooltip title="Edit">
                          <IconButton>
                            <BiEdit
                              className={style.editButton}
                              onClick={() => {
                                setUpdatePopup(true);
                                setEditClubs(data.club_id);
                                setEditFirstName(data.FirstName);
                                setEditSecondName(data.LastName);
                                setEditCountryName(data.country)
                                setEditPhone(data.ContactNumber);
                                setEditEmail(data.Email);
                                setEditCountryName(data.country)
                                setEditUserName(data.Username);
                                setUserId(data._id);
                                setEditRoleName(data.role);
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Share Credentials">
                          <IconButton>
                            <RiShareForwardFill
                              className={style.forward}
                              onClick={() => {
                                setShowSharePopup(true);
                                setCredential({
                                  username: data.Username,
                                  password: data.Password,
                                });
                                setShareEmail(data.Email);
                              }}
                            />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Reset Password">
                          <IconButton>
                            <PiPasswordBold
                              className={style.passwordRest}
                              onClick={() => {
                                setShowPasswordPopup(true);
                                setPasswordId(data._id);
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                              {data.role==="Intern"?
                        <Tooltip title="Performance">
                          <IconButton onClick={()=>{
                            navigate("/performance",{
                              state:{
                                orgID:data.org_id,
                                userID:data._id,
                                clubID:data.club_id[0]
                              }
                            })
                          }}>
                           <img src={PerformanceIcon} alt="performance" width="20px"/>
                          </IconButton>
                        </Tooltip>
                        :""
                        }

                        <Tooltip title="Delete">
                          <IconButton>
                            <RiDeleteBinLine
                              className={style.deleteButton}
                              onClick={() => {
                                setUserId(data._id);
                                setMemberDelete(true);
                                setDeleteName(data.Username);
                              }}
                            />
                          </IconButton>
                        </Tooltip>

                        {/* <div className={style.bottom_right}>
                        {data.is_org === false && data.role === "Intern" ? (
                          <button
                            onClick={() => {
                              navigate("/lesson/mapping", {
                                state: {
                                  organization: data.org_id,
                                  club: data.club_id,
                                  user: data.Username,
                                  tocken: data.auth_token,
                                  id: data._id,
                                },
                              });
                            }}
                          >
                            Lesson Map
                            <RiShareForwardFill className={style.forward} />
                          </button>
                        ) : (
                          ""
                        )}
                      </div> */}
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          <Pagination
            pageCount={Math.ceil(totalUsers / itemsPerPage)}
            onPageChange={handlePageChange}
          />
        </div>
      ) : (
        ""
      )}

      {/* {mentorChange ? (
        <>
          <div
            onClick={() => {
              setMentorChange(false);
            }}
            className={style.RoleOverlay}
          ></div>
          <div className={style.roleSelected}>
            <div className={style.roleCLose}>
              <AiOutlineClose
                onClick={() => {
                  setMentorChange(false);
                }}
              />
            </div>
            <div className={style.roleHead}>
              <h4>Mentor Mapping</h4>
            </div>
            <div className={style.userList}>
              <table>
                <tr>
                  <th>UserName</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Assigned Mentor</th>
                  <th>New Mentor</th>
                  <th>Action</th>
                </tr>

                {userData.length > 0 &&
                  userData.map((data, i) => {
                    return (
                      <tr>
                        <td>{data.Username}</td>
                        <td>{data.Email}</td>
                        <td>{data.role}</td>
                        <td>
                          <button
                            className={style.viewButton}
                            onClick={() => {
                              setMappedMentorList(true);
                              mappedMentorView(data._id);
                            }}
                          >
                            View
                          </button>
                        </td>
                        <td>
                          <select
                            name=""
                            id=""
                            onChange={(e) => {
                              const selectedMenterID = e.target.value;
                              setSelectedMentorId(selectedMenterID);
                            }}
                          >
                            <option value="">Select Mentor</option>
                            {mentorList.length > 0 &&
                              mentorList.map((mentor, i) => {
                                return (
                                  <option value={mentor._id} key={mentor._id}>
                                    {mentor.Username}({mentor.about_experience})
                                  </option>
                                );
                              })}
                          </select>
                        </td>
                        <td>
                          <button
                            onClick={() => {
                              mentorUpdate(selectedMentorId, data._id);
                            }}
                            className={style.mentorAdd}
                          >
                            Add Mentor
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
          </div>
        </>
      ) : (
        ""
      )} */}

      {/* {mappedMentorList ? (
        <>
          <div
            onClick={() => {
              setMappedMentorList(false);
            }}
            className={style.RoleOverlay}
          ></div>
          <div className={style.AssignedMentorList}>
            <div className={style.listClose}>
              <AiOutlineClose
                onClick={() => {
                  setMappedMentorList(false);
                }}
              />
            </div>
            <div className={style.listHead}>
              <h4>Assigned Mentor List</h4>
            </div>
            <div className={style.allMentorList}>
              <table>
                <tr>
                  <th>Name</th>
                  <th>Department</th>
                  <th>Change Mentor</th>
                  <th>Action</th>
                </tr>

                {singleMentorList.length > 0 &&
                  singleMentorList.map((list, i) => {
                    return (
                      <tr>
                        <td>{list.Username}</td>
                        <td>{list.about_experience}</td>{" "}
                        <td>
                          <select
                            name=""
                            id=""
                            onChange={(e) => {
                              const selectedMenterID = e.target.value;
                              setSelectedMentorId(selectedMenterID);
                            }}
                          >
                            <option value="">Select Mentor</option>
                            {mentorList.length > 0 &&
                              mentorList.map((mentor, i) => {
                                return (
                                  <option value={mentor._id} key={mentor._id}>
                                    {mentor.Username}({mentor.about_experience})
                                  </option>
                                );
                              })}
                          </select>
                        </td>
                        <td>
                          <button>Update Mentor</button>
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
          </div>
        </>
      ) : (
        ""
      )} */}

      {memberCreate ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setMemberCreate(false);
              setSelectedClubs([]);
            }}
          ></div>
          <div className={style.memberCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose
                onClick={() => {
                  setMemberCreate(false);
                  setSelectedClubs([]);
                }}
              />
            </div>
            <div className={style.sectionValue}>
              <div className={style.inputValues}>
                <label htmlFor="">First Name</label>
                <input
                  type="text"
                  name=""
                  id="FirstName"
                  placeholder=""
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </div>
              <div className={style.inputValues}>
                <label htmlFor="">Second Name</label>
                <input
                  type="text"
                  name=""
                  id="secondName"
                  placeholder=""
                  onChange={(e) => {
                    setSecondName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={style.sectionValue}>
              <div className={style.inputValues}>
                <label htmlFor="">User Name</label>
                <input
                  type="text"
                  name=""
                  id="UserName"
                  placeholder=""
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                />
              </div>
              <div className={style.inputValues}>
                <label htmlFor=""> Password</label>
                <div className={style.password}>
                  <AiOutlineInfoCircle
                    onClick={() => {
                      SetConditionPopup(true);
                    }}
                  />
                  <input
                    type={showPassword ? "text" : "password"}
                    name=""
                    id="password"
                    placeholder=""
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <BiLockOpenAlt
                    onClick={() => {
                      togglePasswordVisibility();
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={style.sectionValue}>
              <div className={style.inputValues}>
                {/* <label htmlFor="">Phone</label>
                <input
                  type="text"
                  name=""
                  id="phone"
                  placeholder=""
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                /> */}
                 <label htmlFor="">Email</label>
                <input
                  type="mail"
                  name=""
                  id="mail"
                  placeholder=""
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className={style.inputValues}>
               
              </div>
            </div>
            <div className={style.sectionValue}>
              <div className={style.cntryBox}>
                <div className={style.inputValuesBox}>
                  <FormControl fullWidth>
                    <InputLabel id="country-select-label">
                      Select Country
                    </InputLabel>
                    <Select
                      labelId="country-select-label"
                      id="country-select"
                      // value={countryName}
                      label="Select Country"
                      onChange={(e) => setCountryName(e.target.value)}
                    >
                      {countryData.map((country, index) => (
                        <MenuItem key={index} value={country}>
                          {country}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Role
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Select Role"
                      onChange={(e) => {
                        const RoleID = e.target.value;
                        const roleName = roles.find(
                          (role) => role.role_id === RoleID
                        );
                        setSelectedRoleId(RoleID);
                        if (roleName) {
                          setSelectedRoleName(roleName.role_name);
                        }
                      }}
                    >
                      {roles &&
                        roles.length > 0 &&
                        roles.map((role) => (
                          <MenuItem key={role.role_id} value={role.role_id}>
                            {role.role_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>

                {clubSelected ? (
                  <div className={style.clubBox}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Select Club/Batch
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Club/Batch"
                        multiple
                        value={selectedClubs}
                        onChange={(event) =>
                          setSelectedClubs(event.target.value)
                        } // Add this prop
                      >
                        {clubList &&
                          clubList.length > 0 &&
                          clubList.map((club) => {
                            return (
                              <MenuItem
                                key={club.ClubID}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                                value={club.ClubID}
                              >
                                {" "}
                                {club.ClubName}
                                <Checkbox
                                  name={club.ClubID}
                                  id={club.ClubID}
                                  onChange={() =>
                                    handleCheckboxChange(club.ClubID)
                                  }
                                  checked={selectedClubs.includes(club.ClubID)}
                                />
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {/* <select
                className={style.roleSelection}
                name=""
                id=""
                onChange={(e) => {
                  const RoleID = e.target.value;
                  const roleName =
                    e.target.options[e.target.selectedIndex].text;
                  setSelectedRoleId(RoleID);
                  setSelectedRoleName(roleName);
                }}
              >
                <option value="">Select Role</option>
                {roles.length > 0 &&
                  roles.map((role) => {
                    return (
                      <option value={role.role_name} key={role.role_id}>
                        {role.role_name}
                      </option>
                    );
                  })}
              </select> */}
              {/* <div className={style.clubSelection}>
                <p>Select Club/Batch</p>

                {clubSelectShow ? (
                  <MdKeyboardArrowUp
                    onClick={() => {
                      setClubSelectShow(false);
                    }}
                  />
                ) : (
                  <MdKeyboardArrowDown
                    onClick={() => {
                      setClubSelectShow(true);
                    }}
                  />
                )}
              </div> */}
              {/* {clubSelectShow ? (
                <div className={style.clubCheck}>
                  {clubList &&
                    clubList.length > 0 &&
                    clubList.map((club) => {
                      return (
                        <div className={style.clubValue}>
                          <p>{club.ClubName}</p>
                          <input
                            type="checkbox"
                            name={club.ClubID}
                            id={club.ClubID}
                            onChange={handleCheckboxChange}
                          />
                        </div>
                      );
                    })}
                </div>
              ) : (
                ""
              )} */}
            </div>
            <div className={style.createButton}>
              <button
                className={style.create}
                onClick={() => {
                  memberAdd();
                }}
              >
                Create
              </button>
              <button
                className={style.cancel}
                onClick={() => {
                  setMemberCreate(false);
                  setSelectedClubs([]);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {updatePopup ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setUpdatePopup(false);
              setApiError("");
              setErrors({});
            }}
          ></div>
          <div className={style.memberCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose
                onClick={() => {
                  setUpdatePopup(false);
                  setApiError("");
                  setErrors({});
                }}
              />
            </div>
            <div className={style.sectionValue}>
              <div className={style.inputValues}>
                <label htmlFor="">First Name</label>
                <input
                  value={editFirstName}
                  type="text"
                  name=""
                  id=""
                  placeholder=""
                  onChange={(e) => {
                    setEditFirstName(e.target.value);
                  }}
                />
              </div>
              <div className={style.inputValues}>
                <label htmlFor="">Second Name</label>
                <input
                  value={editSecondName}
                  type="text"
                  name=""
                  id=""
                  placeholder=""
                  onChange={(e) => {
                    setEditSecondName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={style.sectionValue}>
              <div className={style.inputValues}>
                <label htmlFor="">User Name</label>
                <input
                  value={editUserName}
                  type="text"
                  name=""
                  id=""
                  placeholder=""
                  onChange={(e) => {
                    setEditUserName(e.target.value);
                  }}
                />
              </div>
              <div className={style.inputValues}>
                {/* <label htmlFor="">Phone</label>
                <input
                  value={editPhone}
                  type="text"
                  name=""
                  id=""
                  placeholder=""
                  onChange={(e) => {
                    setEditPhone(e.target.value);
                  }}
                /> */}
                <label htmlFor="">Email</label>
                <input
                  value={editEmail}
                  type="mail"
                  name=""
                  id=""
                  placeholder=""
                  onChange={(e) => {
                    setEditEmail(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={style.sectionValue}>
              <div className={style.inputValues}>
                <label htmlFor="">Current Role</label>
                <input
                  type="text"
                  name=""
                  id=""
                  value={editRoleName}
                  disabled
                />
              </div>
              <div className={style.inputValues}>
              <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Update Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Update Role"
                    onChange={(e) => {
                      const RoleID = e.target.value;
                      const roleName = roles.find(
                        (role) => role.role_id === RoleID
                      );
                      setSelectedRoleId(RoleID);
                      if (roleName) {
                        setSelectedRoleName(roleName.role_name); // Extract role_name from the object
                      }
                    }}
                  >
                    {roles &&
                      roles.length > 0 &&
                      roles.map((role) => (
                        <MenuItem key={role.role_id} value={role.role_id}>
                          {role.role_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className={style.EditsectionValue}>

        <div className={style.editcntryBox}>
              <FormControl fullWidth>
                      <InputLabel id="country-select-label">
                        Select Country
                      </InputLabel>
                      <Select
                        labelId="country-select-label"
                        id="country-select"
                        value={editCountryName}
                        label="Select Country"
                        onChange={(e) => setEditCountryName(e.target.value)}
                      >
                        {countryData.map((country, index) => (
                          <MenuItem key={index} value={country}>
                            {country}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
  
  
  
                
        </div>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Club/Batch
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Club/Batch"
                  multiple
                  value={editClubs}
                  onChange={(event) => setEditClubs(event.target.value)} // Add this prop
                >
                  {clubList &&
                    clubList.length > 0 &&
                    clubList.map((club) => {
                      const isSelected = editClubs.includes(club.ClubID);

                      return (
                        <MenuItem
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          key={club.ClubID}
                          value={club.ClubID}
                        >
                          {" "}
                          {club.ClubName}
                          <Checkbox
                            name={club.ClubID}
                            id={club.ClubID}
                            checked={isSelected}
                            onChange={() => {
                              handleClub(club.ClubID);
                            }}
                          />
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              {/* <select
                className={style.roleSelection}
                name=""
                id=""
                onChange={(e) => {
                  const selectedRoleID = e.target.value;
                  const roleName =
                    e.target.options[e.target.selectedIndex].text;
                  setSelectedRoleId(selectedRoleID);
                  setSelectedRoleName(roleName);
                }}
              >
                <option value="">Update Role</option>

                {roles &&
                  roles.length > 0 &&
                  roles.map((role) => {
                    return (
                      <option value={role.role_id}>{role.role_name}</option>
                    );
                  })}
              </select> */}
              {/* <div className={style.clubSelection}>
                <p>Select Club/Batch</p>

                {EditClubSelectShow ? (
                  <MdKeyboardArrowUp
                    onClick={() => {
                      setEditClubSelectShow(false);
                    }}
                  />
                ) : (
                  <MdKeyboardArrowDown
                    onClick={() => {
                      setEditClubSelectShow(true);
                    }}
                  />
                )}
              </div> */}
              {/* {EditClubSelectShow ? (
                <div className={style.clubEditCheck}>
                  {clubList &&
                    clubList.length > 0 &&
                    clubList.map((club) => {
                      const isSelected = editClubs.includes(club.ClubID);
                      return (
                        <div className={style.clubValue} key={club.ClubID}>
                          <p>{club.ClubName}</p>
                          <input
                            type="checkbox"
                            name={club.ClubID}
                            id={club.ClubID}
                            checked={isSelected}
                            onChange={() => {
                              handleClub(club.ClubID);
                            }}
                          />
                        </div>
                      );
                    })}
                </div>
              ) : (
                ""
              )} */}
            </div>
            <div className={style.createButton}>
              <button
                className={style.create}
                onClick={() => {
                  memberEdit();
                }}
              >
                Update
              </button>
              <button
                className={style.cancel}
                onClick={() => {
                  setUpdatePopup(false);
                  setApiError("");
                  setErrors({});
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {memberDelete ? (
        <>
          <div
            onClick={() => {
              setMemberDelete(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.deletePopup}>
            <div className={style.deleteClose}>
              <AiOutlineClose
                onClick={() => {
                  setMemberDelete(false);
                }}
              />
            </div>
            <div className={style.deleteSection}>
              <div className={style.popupText}>
                <p>
                  Are you sure you want to delete <span>{deleteName}</span> ?
                </p>
              </div>
              <div className={style.popupDelete}>
                <button
                  className={style.orgDelete}
                  onClick={() => {
                    DeleteMember();
                  }}
                >
                  Delete
                </button>
                <button
                  className={style.orgDeleteCancel}
                  onClick={() => {
                    setMemberDelete(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {showPasswordPopup ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setShowPasswordPopup(false);
            }}
          ></div>
          <div className={style.passwordReset}>
            <div className={style.resetClose}>
              <AiOutlineClose onClick={() => handleCancelClick()} />
            </div>
            <div className={style.passworSection}>
              {/* {passwordMessage && (
                <div style={{ color: "red", fontSize: "1.2rem" }}>
                  {passwordMessage}
                </div>
              )} */}
              <div className={style.resetInput}>
                <AiOutlineInfoCircle
                  onClick={() => {
                    SetConditionPopup(true);
                  }}
                />
                <input
                  type={showPassword ? "text" : "password"}
                  name=""
                  id=""
                  placeholder="Password"
                  value={editPassword}
                  onChange={(e) => {
                    handlePasswordChange(e);
                    setpasswordMessage("");
                  }}
                />
                <BiLockOpenAlt
                  onClick={() => {
                    togglePasswordVisibility();
                  }}
                />
              </div>
              <div className={style.confirmResetInput}>
                <input
                  type={showEditPassword ? "text" : "password"}
                  name=""
                  id=""
                  onClick={() => {
                    if (
                      editPassword !== "" &&
                      (/[A-Z]/.test(editPassword) === false ||
                        /\d/.test(editPassword) === false ||
                        editPassword.length < 6 ||
                        editPassword.length > 15)
                    ) {
                      setpasswordMessage("Password does not match Criteria");
                      setPasswordCondition(true);
                    }
                  }}
                  placeholder="Retype Password"
                  value={confirmPassword}
                  onChange={(e) => handleConfirmPasswordChange(e)}
                />
                <BiLockOpenAlt
                  onClick={() => {
                    togglePassword();
                  }}
                />
              </div>
            </div>
            <div className={style.resetButtons}>
              <button
                className={style.resetUpddate}
                onClick={() => {
                  if (
                    editPassword !== "" &&
                    (/[A-Z]/.test(editPassword) === false ||
                      /\d/.test(editPassword) === false ||
                      editPassword.length < 6 ||
                      editPassword.length > 15)
                  ) {
                    setpasswordMessage("Password does not match Criteria");
                    setPasswordCondition(true);
                  } else {
                    handleUpdateClick();
                  }
                }}
              >
                Update
              </button>
              <button
                className={style.restCancel}
                onClick={() => handleCancelClick()}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {conditionPopup ? (
        <>
          <div
            className={style.secondOverlay}
            onClick={() => {
              SetConditionPopup(false);
            }}
          ></div>
          <div className={style.PasswordPopup}>
            <div className={style.content}>
              <ul>
                <p>Password must include:</p>
                <li>6-15 Characters</li>
                <li>At least 1 capital letter</li>
                <li>At least 1 number</li>
                <li>At least 1 special character</li>
                <li>No spaces</li>
              </ul>
            </div>
            <div className={style.action}>
              <button
                onClick={() => {
                  SetConditionPopup(false);
                }}
              >
                OK
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {showSharePopup ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setShowSharePopup(false);
              setCredential({ username: "", password: "" });
            }}
          ></div>
          <div className={style.SharePopup}>
            <div className={style.resetClose}>
              <AiOutlineClose
                onClick={() => {
                  setShowSharePopup(false);
                }}
              />
            </div>
            <div className={style.SharePopupSection}>
              <div className={style.ShareSelection}>
                <div className={style.SelectType}>
                  <input
                    type="radio"
                    onClick={credentialShare}
                    name="sharecredential"
                    id="email"
                  />
                  <label for="email">Share via email</label>
                </div>
                <div className={style.SelectType}>
                  <input
                    type="radio"
                    onClick={handleCopyCredentials}
                    name="sharecredential"
                    id="copy"
                  />
                  <label for="copy">Copy Credentials</label>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default User;
